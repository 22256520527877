.argeo_help_container {
    position: absolute;
    top: 4.5rem;
    left: 0px;
    right: 0px;
}

.argeo_help {
    max-width: 50%;
    margin: auto;
}

.admin {
    position: relative;
    min-height: 20rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1 1 0;

    background-color: var(--background);
    overflow: hidden;
    height: calc(100% - 50px);
}
.library {
    background-color: var(--background);
    padding: 2rem;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
}
.map {
    width: 100%;
    height: 100%;

    .overview-ui {
        width: 100%;
        height: 100%;
    }

    .map-container {
        width: 100%;
        height: 100%;
    }
}
.project {
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1 1 0;

    height: calc(100% - 76px);
    width: calc(100% - 4px);

    top: 74px;
    left: 2px;
}
.outlet {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
}
.full-page {
    width: 100%;
    height: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
}
.giro3d,
.giro3d-container,
.giro3d-seismic-container {
    flex: auto;
    height: 100%;
    width: 100%;
    position: relative;
    overflow-x: hidden;
}

.split-tab {
    background-color: var(--foreground);
    display: flex;
    gap: 2px;

    height: 100%;
    width: 100%;

    &.horizontal {
        flex-direction: row;
    }
    &.vertical {
        flex-direction: column;
    }

    & > div {
        background-color: var(--background);

        &.minimal {
            position: relative;
            height: calc(100% - 1.5rem);
            flex-grow: 0;
            overflow-y: auto;
            overflow-x: hidden;
            pointer-events: initial;
        }
        &:not(.minimal) {
            flex-grow: 1;
        }

        &.container-3d {
            position: relative;
        }
    }
}

.giro3d-seismic-container,
.giro3d-minimap-container {
    pointer-events: all;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--emphasize-dark);
}
.pane-button.close-view {
    pointer-events: all;
    position: absolute;
    top: 1rem;
    left: 1rem;
    & > i {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}

.giro3d-inspector {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 100%;
    overflow: auto;
    z-index: 2;
    pointer-events: initial;
}

.map-container-loader {
    z-index: 1;
    height: 100%;
    height: 100%;

    text-align: center;
    display: grid;
    align-items: center;

    color: var(--text-high-emphasis);
}

.giro3d-controls {
    position: absolute;
    left: 0;
    bottom: 2rem;
    width: 100%;
    height: 50rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    padding: 1rem;
    gap: 1rem;

    pointer-events: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.giro3d-controls button {
    pointer-events: initial;
}

.giro3d-selection-info {
    position: relative;
    top: 0;

    background-color: var(--background);
    border: solid 2px var(--foreground);
    max-width: 20rem;
    padding: 0.25rem;
    margin: 0.5rem;
    pointer-events: visible;
    max-height: 100%;
    height: fit-content;

    overflow-y: auto;
}

.pane-controls {
    position: absolute;
    left: 0;
    bottom: calc(50% - 15px);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    pointer-events: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.pane-toggle {
    pointer-events: initial;
    border: solid 2px var(--foreground);
    color: var(--bs-body-color);
    background-color: var(--background);

    height: fit-content;

    &.left {
        border-left: none;
        border-radius: 0 0.5rem 0.5rem 0;
    }

    &.bottom {
        border-bottom: none;
        border-radius: 0.5rem 0.5rem 0 0;
    }

    &.right {
        border-right: none;
        border-radius: 0.5rem 0 0 0.5rem;
    }
}

.giro3d-ui {
    max-height: 100%;
    height: 100%;
}

.giro3d-lower-ui {
    bottom: 0;
    align-items: flex-end;
}

.giro3d-upper-ui {
    height: calc(50% - 2rem);
    max-height: calc(50% - 2rem);
}

.giro3d-upper-ui,
.giro3d-lower-ui {
    position: absolute;
    width: 100%;

    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;

    justify-content: space-between;

    & > div:nth-child(2) {
        flex-grow: 1;
    }

    & > div:nth-child(2) > div {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }

    & > div:nth-child(2) > div > div:nth-child(2) {
        flex-grow: 1;
    }

    .pane-toggle {
        margin: 0 auto;
        bottom: 0;
    }
}

.map-upper-ui {
    position: absolute;
    top: 0;
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: space-between;
    align-items: flex-start;

    div:first-child {
        display: flex;
        gap: 0.25rem;
        padding: 0.25rem;

        button {
            border: none;
            background-color: var(--emphasize-dark);
            border-radius: 4px;
            color: var(--text-medium-emphasis);
            font-size: 1.25rem;
            line-height: 1.25rem;
            margin: 0;
            height: 1.75rem;
            width: 1.75rem;

            &.highlight,
            &:hover {
                background-color: var(--background);
            }
            &.highlight:hover {
                background-color: var(--emphasize-dark);
            }
        }
    }

    div:nth-child(2) {
        background-color: var(--background);
        margin: 0.25rem;
        border-radius: 3px;
        width: 20rem;
    }
}

.map-lower-ui {
    position: absolute;
    bottom: 0;
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: space-between;
    align-items: flex-start;

    padding: 0.25rem;
}

.map-controls {
    padding: 1rem;
}
.map-controls.lower {
    margin-bottom: -27px;
}

.line-follow-menu {
    flex-grow: 1;
    background-color: transparent;
    border: solid 2px transparent;
    border-radius: 5px;
    transition: 0.5s;
    pointer-events: fill;
    padding: 0 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;

    &:hover {
        background-color: var(--background);
        border-color: var(--foreground);
        padding: 1rem;
        box-shadow: var(--bs-box-shadow);
    }

    .controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    button.borderless i {
        width: 1.5rem;
    }

    button:not(.borderless) {
        border-radius: 5px;
        --bs-btn-bg: var(--background);
        --bs-btn-disabled-bg: var(--emphasize-dark);

        &:disabled {
            opacity: 50%;
        }
    }

    i {
        filter: drop-shadow(0 0 0.25rem black);
    }

    .slider {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 3px;
        background: var(--bs-body-color);
        outline: none;
        cursor: pointer;
        box-shadow: var(--bs-box-shadow);

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 2px;
            height: 1rem;
            background: var(--yellow-2);
            cursor: pointer;
            box-shadow: var(--bs-box-shadow);
        }
        &::-moz-range-thumb {
            width: 2px;
            height: 1rem;
            background: var(--yellow-2);
            cursor: pointer;
            box-shadow: var(--bs-box-shadow);
        }
    }
}

.map-compass {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.map-compass-letter {
    background-color: var(--menu-bg-color);
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 0.5rem;
    font-weight: bold;
}
.map-compass > img {
    width: 2.5rem;
}

.giro3d-zoom {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 1rem;
}

.map-controls-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: fit-content;
    flex-wrap: nowrap;
    align-items: flex-end;
    gap: 1rem;
}
.map-controls-row.no-gap {
    gap: 0;
}
.map-controls > div:not(.map-controls-row) {
    overflow: hidden;
}

.map-control,
.map-controls .btn {
    width: 3rem;
    height: 3rem;
    border-radius: 1.5rem;
    padding: 0;

    --bs-btn-active-bg: var(--dark-gray-3);
    border: solid 2px var(--menu-highlight-color);
    background-color: var(--menu-bg-color);
    color: white;

    font-family: 'Font Awesome 6 Pro';
    font-weight: 300;
    font-size: 1.5rem;
    pointer-events: all;
}

.map-controls button:hover.giro3d-zoom-in {
    color: #bfe6c9;
    border-color: #bfe6c9;
}
.map-controls button:hover.giro3d-zoom-out {
    color: #f19c94;
    border-color: #f19c94;
}

button:hover.giro3d-save {
    color: var(--blue-4);
    border-color: var(--blue-4);
}
button:hover.giro3d-cameras {
    color: #feeab6;
    border-color: #feeab6;
}
button:hover.giro3d-orbit,
button.giro3d-orbit.active {
    color: var(--amethyst-4);
    border-color: var(--amethyst-4);
}
button:hover.giro3d-pan,
button.giro3d-pan.active {
    color: var(--saphire-4);
    border-color: var(--saphire-4);
}
button:hover.giro3d-dolly,
button.giro3d-dolly.active {
    color: var(--citrine-4);
    border-color: var(--citrine-4);
}
button:hover.giro3d-follow,
button.giro3d-follow.active {
    color: var(--emerald-4);
    border-color: var(--emerald-4);
}

.giro3d-controls-tooltip .tooltip-inner {
    max-width: calc(1.5 * var(--bs-tooltip-max-width));
}
.tooltip.bs-tooltip-auto {
    z-index: 2000;
}

.project-space .map-statusbar {
    border-radius: 0 0.5rem 0.5rem 0;
}
.map-statusbar {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    background-color: var(--emphasize-dark);
    opacity: 1;

    align-items: center;
    justify-content: space-between;

    font-size: 0.9rem;
    color: var(--text-high-emphasis);

    padding: calc(var(--spacer) / 2) var(--spacer);

    flex-shrink: 1;
}
.map-statusbar-scaleline {
    justify-self: flex-end;
    width: 250px;
    float: right;
}
.map-statusbar-marker {
    color: var(--primary-light);
    justify-self: flex-end;
    max-height: 0.9rem;
}
.map-statusbar-position {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 0 0.5rem;
    flex-wrap: wrap;

    .coordinate-component {
        span {
            color: var(--text-medium-emphasis);
        }
    }
}
.map-statusbar-position .coordinate-component {
    width: 6rem;
    max-height: 21.6px;
    overflow: hidden;
}
.map-lower-ui .map-statusbar-position .coordinate-component {
    width: 4rem;
}

.ol-scale-line {
    position: unset;
    float: right;
    background: none;
    margin: 0;
    padding: 0;
}
.ol-scale-line-inner {
    text-align: right;
    padding: 0px;
    padding-right: 1rem;
    font-size: 0.8rem;
    color: #dadbda;
    border-color: #dadbda;
}

.edit-dataset-container {
    display: flex;
    flex-direction: column;
}

.wizard-progress .active {
    font-weight: bold;
    color: var(--bs-warning-hover);
}

.wizard-progress :not(.active) {
    color: #888888;
}

.centered-form {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--bs-body-color);
    margin: 2rem;
    border-radius: var(--border-radius);
    min-width: 50vw;
    min-height: 50vh;
}

.sourcefile-table {
    overflow-y: auto;
    max-height: 200px;
}

/* Removes the blue autofull background from autocompleted fields */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition:
        background-color 5000s ease-in-out 0s,
        color 5000s ease-in-out 0s;
}

#big-upload-field {
    border-width: 0.5rem;
    border-color: var(--menu-active-color);
}

.admin .nav .nav-item h2,
.modal-body .nav .nav-item h2 {
    font-size: 1.25rem; /* Use h5 font-size */
}

.admin tbody,
.admin td,
.admin tfoot,
.admin th,
.admin thead,
.admin tr {
    padding: var(--spacer);
}

.nav-tabs > .nav-item {
    max-height: 4rem;
    overflow-y: hidden;
    border: 0px solid var(--menu-bg-color);
    transition:
        max-height 0.5s,
        padding 0.5s,
        border-top 0.5s ease-out,
        border-bottom 0.5s ease-out;
}
.nav-tabs > .nav-item > ul {
    pointer-events: none;
}
.nav-tabs > .nav-item.active > ul {
    pointer-events: auto;
}

.menu-tooltip {
    text-transform: uppercase;
    font-size: 0.8rem;
}

.submenu-tooltip {
    ul {
        margin: 0;

        li {
            list-style: none;
            text-align: left;
            line-height: 1rem;

            i {
                padding-right: 0.25rem;
            }
        }
    }
}

.table {
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: none;
}

.form-text.text-muted {
    --bs-secondary-color: var(--dark-gray-4);
}

.map-pane-jumbo-icon {
    color: var(--primary);
    font-size: 10rem;
}

.map-pane-title,
.full-page-title {
    font-size: 1.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    width: 100%;
    min-height: 2.265rem;
}
.full-page-title {
    text-transform: uppercase;
    font-weight: bold;
}
.full-page-title.vertical {
    writing-mode: vertical-lr;
}
.map-pane-title-sub {
    font-size: 1rem;
}
.map-pane-title-bar,
.map-pane-list-title-bar,
.full-page-title-bar {
    display: flex;
    direction: ltr;
    overflow-x: hidden;
    flex-wrap: nowrap;
    padding-bottom: var(--spacer);
    overflow: visible;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
    align-items: center;
}
.map-pane-title-bar-buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.limit-width.map-pane-title-bar,
.limit-width.map-pane-list-title-bar,
.limit-width.full-page-title-bar {
    max-width: 25.125rem;
}

.full-page-table {
    width: 100%;
    overflow-y: scroll;

    table {
        width: 100%;
    }

    th {
        position: sticky;
        top: 0;
        border-bottom: solid 2px var(--foreground);
        background-color: var(--emphasize-dark);
        padding: 0rem 1rem;
        white-space: nowrap;
    }

    td:last-child {
        text-align: right;
        width: 0;
    }

    .library-list-item td:nth-last-child(2) {
        text-align: center;
        width: 0;
    }

    th:last-child {
        text-align: right;
        width: 4rem;
    }

    .library-list-group {
        td {
            border-bottom: solid 1px var(--foreground);
            padding: 0 0.5rem;
            &:first-child {
                font-weight: 700;
                label {
                    display: flex;
                    cursor: pointer;
                }
            }
        }
    }

    tr.library-list-item {
        border-left: solid 2px var(--foreground);
        td {
            height: 1rem;
            padding: 0 0.5rem;

            &:first-child {
                word-break: break-word;
            }
        }
        &:hover {
            background-color: var(--foreground);
        }
    }

    .dropdown-arrow > .arrow::after {
        color: var(--light-gray-2);
        font-weight: 400;
    }

    .inline-drop-menu {
        margin-left: auto;
        margin-right: 0;
    }
}

.map-pane-body {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.map-pane-body.spacer {
    padding-top: 2rem;
}
.map-pane-section {
    margin-bottom: calc(4 * var(--spacer));
}
.map-pane-centred-fill {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.map-pane-centred-fill.centred-text {
    text-align: center;
}
.map-pane-large-text {
    color: var(--dark-gray-4);
    font-size: 2.5rem;
    font-weight: bolder;
}
.map-pane-list-title {
    font-weight: bold;
    text-transform: uppercase;
    margin: var(--spacer) 0;
    width: 100%;
}

.annotations-menu {
    width: 25rem;
}
.view-options button {
    margin-top: var(--spacer);
}
.tab-menu .nav {
    flex-direction: column;
    flex: 1 1 0;
    height: inherit;
}
.tab-menu .nav-item {
    flex-direction: column;
}
.tab-menu .nav-item span {
    padding: 0 1rem;
    font-size: 1rem;
    text-transform: uppercase;
}
.tab-menu .nav-link {
    border-radius: 0;
    margin: 0;
    color: var(--text-high-emphasis);
}
.tab-menu .nav-link:hover,
.tab-menu .nav-link.active {
    background: var(--emphasize-dark);
    color: var(--text-high-emphasis);
    cursor: pointer;
    border-color: transparent;
}
.tab-menu .nav-link i {
    display: inline-block;
    font-size: 1.2rem;
    min-width: 2.4rem;
    text-align: center;
    padding-left: var(--spacer);
}
.tab-menu .nav-link:hover i,
.tab-menu .nav-link.active i {
    color: var(--green-5);
}
.tab-menu-header {
    padding: var(--spacer) calc(3.5 * var(--spacer));
}
.admin .tab-menu {
    border-right: solid 2px var(--emphasize-dark);
    margin: 0;
}
.admin .tab-content {
    flex-grow: 1;
    overflow-y: scroll;

    table {
        width: 100%;
    }
}

/* popover styling */
.popup-body {
    width: 15rem;
}

/* Layer list styling */
.layer-group {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding: 0 var(--spacer);
}
.layer-group-header {
    display: flex;
    align-items: center;
    width: 100%;
}

.layer-group-header input {
    padding: var(--spacer);
}

.layer-group-header i {
    flex: 0 0 0;
    justify-self: flex-end;
}

.layer-group-header label {
    font-weight: 500;
    flex: 1 1 0;
    padding: 0 var(--spacer);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.layer-group ul {
    padding: 0 var(--spacer);
}

.layer-group > ul.hidden {
    display: none;
    max-height: 0;
    max-width: 0;
}

/* Overlay list styling */
.overlay-group {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding: 0 var(--spacer);
}
.overlay-group-header {
    display: flex;
    align-items: center;
    width: 100%;
}

.overlay-list-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: calc(2 * var(--spacer));
}
.overlay-group-header input,
.overlay-list-item input {
    padding: var(--spacer);
}
.overlay-group-header i {
    flex: 0 0 0;
    justify-self: flex-end;
}
.overlay-group-header label,
.overlay-list-item label {
    font-weight: 500;
    flex: 1 1 0;
    padding: 0 var(--spacer);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

/* Navbar styling */
.navbar {
    width: 100%;
    z-index: 2;
    padding: 0;
    position: relative;
    padding: 1.5rem 0 3rem 0;
}

.navbar-brand img {
    height: 2rem;
}

.navbar-collapse {
    /* display: flex; */
    /* flex-direction: column; */

    flex-grow: 0;
}

.navbar a {
    color: var(--bs-body-color);
}

.navbar .nav-item {
    list-style: none;
}

.navbar-tools {
    display: inline-flex;
    flex-direction: row;
    gap: var(--spacer);
    align-items: center;
}

.back-button {
    background-color: transparent;
    color: var(--menu-close-color);
    border: none;
    font-size: x-large;
}

/* Login */
.login-bg {
    background: url('./images/argeo.jpg') bottom left/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    color: black;
    object-fit: contain;
    justify-content: center;
}
.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    background-color: var(--background);
    border-radius: 5px;
    color: var(--text-high-emphasis);
    box-shadow: var(--bs-box-shadow);

    img {
        height: 14rem;
        margin: 2rem 4rem;

        @media only screen and (max-height: 600px) {
            display: none;
        }
    }

    .login-title {
        font-weight: bold;
        font-size: 3.5rem;
        margin: 1rem 5rem;
    }

    .login-footer {
        text-align: center;
        margin: 2rem 0;

        button {
            font-size: 1.5rem;
            border: none;
            border-radius: 5px;
            box-shadow: var(--bs-box-shadow);
            background-color: var(--foreground);
            color: var(--text-medium-emphasis);
            font-weight: bold;
            width: 15rem;
            height: 3rem;
        }
    }
}

.geometry-readout {
    .edge {
        display: flex;
        flex-wrap: wrap;
        gap: 0 0.5rem;
        padding: 0 1rem;

        color: var(--text-disabled);
        font-size: smaller;

        i {
            padding-right: 0.25rem;
        }

        span {
            font-weight: bold;
            padding-right: 0.25rem;
        }
    }

    .aggregate {
        font-size: 1rem;

        td:first-child {
            font-weight: bold;
        }
    }

    .vertex {
        display: flex;
        padding: 0 0.25rem;
        background-color: var(--emphasize-dark);
        gap: 0.25rem;

        & > div:nth-child(1) {
            font-weight: bold;
        }

        & > div:nth-child(2) {
            display: flex;
            flex-wrap: wrap;
            flex-grow: 1;
            gap: 0 0.5rem;
        }

        & > div:nth-child(1),
        & > div:nth-child(3) {
            display: flex;
            flex-wrap: wrap;
            flex-shrink: 1;
            gap: 0;

            justify-content: space-around;
            align-items: center;
        }

        form {
            display: flex;
            flex-direction: column;
            & > div {
                display: flex;
                flex-direction: row;
                flex-grow: 1;
            }

            .input-row {
                padding-bottom: 0.25rem;
            }
        }

        .coordinate-component {
            &.x {
                color: var(--X-desaturated);
            }
            &.y {
                color: var(--Y-desaturated);
            }
            &.z {
                color: var(--Z-desaturated);
            }
        }
    }
}

.compass {
    .compass_line.x,
    .compass_line-cross.x {
        border-color: var(--X);
    }
    .compass_line.y,
    .compass_line-cross.y {
        border-color: var(--Y);
    }
    .compass_line.z,
    .compass_line-cross.z {
        border-color: var(--Z);
    }

    .coordinate-component {
        &.x {
            color: var(--X-desaturated);
        }
        &.y {
            color: var(--Y-desaturated);
        }
        &.z {
            color: var(--Z-desaturated);
        }
        &.highlighted span {
            color: #69d291;
        }
    }
}

.disabled .compass .coordinate-component {
    pointer-events: none;
}

.giro3d .map-statusbar-position {
    display: flex;
    flex-wrap: wrap;
    min-width: 21rem;
}

.coordinate-buttons {
    max-width: fit-content;
}

.coordinate-box-faded {
    width: 11rem;
    color: #ebebec80;
    white-space: nowrap;
}

.coordinates-result {
    color: var(--green-2);
    text-align: left;
}

.coordinates-result-alt {
    color: var(--bs-warning-hover);
}

.coordinates-result-neutral {
    color: #ffffff;
}

.coordinates-result-secondary {
    color: var(--bs-info);
}

.coordinates-result-tag {
    color: var(--green-2);
    width: 1.8rem;
}

.attribute-table tr.active {
    border-left: 2px solid var(--green-2);
    color: var(--menu-active-color);
}

/* floating window */
.floating-window {
    position: absolute;
    display: flex;
    top: 2rem;
    right: 6rem;
    max-height: 90%;
    background: var(--menu-bg-color);
    border-radius: 2rem;
    border-color: var(--menu-divider-color);
    border-width: 1px;
    width: 22rem;
}

.floating-window-close {
    color: var(--bs-body-color);
}

.ol-tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
    font-size: 12px;
    cursor: default;
    user-select: none;
}
.ol-tooltip-measure {
    opacity: 1;
    font-weight: bold;
}

.feature-info-dl {
    display: flex;
    flex-wrap: wrap;
}

.feature-info-dt {
    color: gray;
    width: 50%;
}

.feature-info-dd {
    width: 50%;
    color: white;
}

.invalid {
    text-align: center;
    color: #fd281b;
    padding-bottom: 1rem;
}

.spread-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

/* Giro3d Inspector */
.giro3d-container .lil-gui.root {
    position: absolute;
}

.big-modal-text {
    font-size: 2.6rem;
    font-weight: bold;
    text-align: center;
    padding-bottom: calc(2 * var(--spacer));
}
.small-modal-text {
    margin: auto;
    text-align: center;
}

.btn-amber {
    margin: 1rem auto;
    border-color: var(--bs-warning);
    color: var(--bs-warning);
}
.btn-amber:hover {
    border-color: var(--bs-warning-hover);
    color: var(--bs-warning-hover);
}

.btn-text-link {
    margin: 1rem auto;
    background-color: transparent;
    text-decoration: underline;
    text-align: center;
}
.btn-text-link:hover,
.btn-text-link:focus {
    color: var(--menu-active-color);
}
.btn-text-link:focus-visible {
    border-color: var(--element-border-color);
}
.btn-text-link.green {
    color: var(--green-3);
}
.btn-text-link.red {
    color: var(--red-3);
}

.borderless.btn-list-item-close {
    font-size: larger;
    color: var(--bs-body-color);
    border-radius: 50%;
    align-self: flex-end;
    margin-right: 1.125rem;
    transform: translateY(0.5rem);
    height: 2.1rem;
    width: 2.1rem;
}
.btn-list-item-close:hover {
    color: var(--X);
    background-color: black;
}

.comment-close-container {
    overflow: visible;
    height: 0;
    float: right;
}
.btn-edit-comment-close {
    display: none;
    padding: 0;
    width: 1.25rem;

    font-size: larger;
    color: var(--bs-body-color);
    background-color: transparent;
    border: none;
    align-self: flex-end;
    margin-right: 0.25rem;
    margin-top: 0.125rem;
    color: var(--red-2);
}
.btn-edit-comment-close:active,
.btn-edit-comment-close:hover {
    color: var(--red-3);
}

.comment-form:hover > div > .btn-edit-comment-close {
    display: block;
}

.dropdown-arrow {
    cursor: pointer;
}
.dropdown-arrow input {
    width: 0;
    opacity: 0;
}
.dropdown-arrow .arrow::after {
    font-family: 'Font Awesome 6 Pro';
    content: '\f107';
    font-size: 1.125rem;
    color: var(--yellow-3);
}
.dropdown-arrow input:checked + .arrow::after {
    content: '\f106';
}

.styled-select {
    background: transparent;
    overflow: hidden;
    width: 100%;
}
.styled-select > select {
    appearance: none;
    color: var(--bs-body-color);
    background: transparent;
    width: 100%;
    border: none;
    border-radius: 0;
    padding: none;
    padding-right: 2.5rem;
    padding-left: 0.5rem;
    height: 2rem;
    text-overflow: ellipsis;
}
.styled-select > select:disabled {
    opacity: 50%;
}
.styled-select > i {
    font-size: larger;
    margin-left: -2rem;
    pointer-events: none;
}
.styled-select > select > option {
    color: var(--bs-body-color);
    background: var(--bs-body-bg);
    border-radius: 0;
}
.styled-select > select::-webkit-scrollbar {
    background: var(--bs-body-bg);
}

.auto-center {
    margin-left: auto;
    margin-right: auto;
    width: min-content;
}
.auto-center-v {
    margin-top: auto;
    margin-bottom: auto;
    height: min-content;
}

.notification-bubble {
    position: relative;
    max-height: 0;
    width: 1.25rem;

    top: 0.1rem;
    right: 0rem;
    float: right;
}
.admin-bubble-icon > .notification-bubble {
    float: left;
    right: unset;
    left: 2rem;
    top: -0.25rem;
}
.notification-bubble > i {
    position: absolute;
    right: 0.125rem;
    top: 0.25rem;

    font-size: 1.125rem;
    color: #b51d13;
}
.admin-bubble-icon > .notification-bubble > i {
    font-size: 1.125rem;
}
.notification-bubble > span {
    display: block !important;

    position: absolute;
    width: 100%;
    right: 0.625rem;
    top: -0.05rem;

    font-size: 0.625rem;
    font-weight: bold;
    color: white;

    user-select: none;
}
.admin-bubble-icon > .notification-bubble > span {
    font-size: 0.625rem;
    text-align: center;
    right: unset;
    padding: 0;
    top: 0.3rem;
    left: -0.4rem;
}
.admin-bubble-icon {
    display: contents;
}

.page-separator {
    background-color: #0f0f0f;
    width: 100%;
    height: 2px;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}
.pane-list-item {
    display: flex;
    flex-direction: row;
    border-top: 1px solid var(--dark-gray-0);
    padding: calc(2 * var(--spacer)) 0;
}
/* .pane-list-item:hover {
    background-color: #090709;
} */
.pane-list-item-title {
    font-weight: bold;
}
.pane-list-item-subtitle {
    font-size: 0.8rem;
    color: #72a9fc;
}
.pane-list-item > i {
    padding-right: 0.5rem;
    font-size: 2rem;
    margin: auto;
}

.annotation-viewfilter {
    background-color: var(--dark-gray-2);
    margin: 0 -1.5rem;
    padding: 0 1.5rem;
    line-height: 2.25rem;
}
.annotation-viewfilter > label {
    float: right;
    margin: 0.75rem 0;
}

.filter-count-outer {
    height: 0;
    width: 0;
    float: right;
    transform: translateX(0.5rem) translateY(-0.5rem);
}
.filter-count {
    font-size: 0.75rem;
    line-height: 1.125rem;
    background-color: var(--menu-bg-color);
    color: var(--bs-body-color);
    border: solid 1px white;
    height: 1.25rem;
    width: 1.25rem;
    text-align: center;
    border-radius: 50%;
    float: right;
}

.annotation-filters > div > .checkbox {
    padding-top: 0.5rem;
}
.annotation-filters > div > .checkbox > label {
    color: var(--bs-body-color);
}

.settings-group {
    border-bottom: solid var(--menu-border-color) 1px;
    width: 100%;
}
.settings-group:first-of-type {
    border-top: solid var(--menu-border-color) 1px;
}
.settings-main-toggle {
    display: flex;
    height: 3.625rem;
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
}
.settings-main-toggle > div {
    width: 2rem;
    display: flex;
    justify-content: center;
}
.settings-main-toggle > div > i {
    font-size: 1rem;
    color: var(--light-gray-2);
    -webkit-transition: 0.2s;
    transition: 0.2s;
}
.settings-main-toggle > div > i.active {
    color: var(--green-3);
}
.settings-main-toggle > .settings-label {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
}
.settings-main-toggle > .settings-label > span {
    flex-grow: 1;
}
.settings-dropdown {
    border-top: solid var(--menu-border-color) 1px;
    padding: 0.5rem 0;
}
.settings-dropdown .settings-group:first-of-type {
    border-top: none;
}
.settings-dropdown .settings-group:last-of-type {
    border-bottom: none;
}
.settings-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.125rem;
    padding: 0.5rem;
    margin-left: 2.5rem;
    gap: 0.5rem;
}
.settings-row > span {
    flex-grow: 1;
}

.settings-pane {
    height: 100%;
    overflow-x: hidden;
}

.data-group-header,
.data-item {
    transition: background-color 0.1s;
}

.data-group {
    position: relative;
    border-bottom: solid var(--menu-border-color) 1px;
    width: 100%;
    max-width: 25.125rem;
}
.data-group.hovered::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.2;
    background-color: var(--green-4);
}
.data-group:first-of-type {
    border-top: solid var(--menu-border-color) 1px;
}
.data-group-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 3rem;
    gap: 1rem;
    padding-left: 0.5rem;
}
.data-group-label {
    display: flex;
    flex-direction: row;

    flex-grow: 1;
}
.data-group-label > span {
    flex-grow: 1;
    text-transform: capitalize;
    color: var(--yellow-4);
}
.data-group-label.custom > span {
    color: var(--blue-4);
}
.data-item {
    width: 100%;
    max-width: 23.875rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0.5rem;
    min-height: 3rem;
    height: fit-content;
    gap: 1rem;
    border-top: solid var(--menu-border-color) 1px;
}
.data-item > span,
.data-item > .list-item-title {
    flex-grow: 1;
    overflow-wrap: break-word;
    word-break: break-all;
    padding: 0;
    text-align: left;
}
.data-group-header.highlighted,
.data-item.highlighted,
tr.highlighted > * {
    background-color: var(--primary-split-desaturated);
}
.data-item > span.indicator-3d {
    opacity: 0.5;
    word-break: keep-all;
}

.user-item,
.access-item {
    min-height: 3rem;
    height: fit-content;
    border-top: solid var(--menu-border-color) 1px;
}
.user-item:last-of-type,
.access-item:last-of-type {
    border-bottom: solid var(--menu-border-color) 1px;
}
.user-item > td > .centre-drop-menu {
    margin: 0 auto;
}
.access-admin-header > th:nth-child(4),
.user-admin-header > th:nth-child(3),
.user-admin-header > th:nth-child(4) {
    text-align: center;
}

.admin-header {
    display: flex;
    gap: 1rem;
}
.admin-header > .multiselect,
.admin-header > input,
.admin-header > div {
    flex-grow: 1;
    width: unset;
}

.admin-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.admin-actions > div {
    padding-left: 0.5rem;
    margin: auto 0;
}

.searchbox {
    display: flex;
    height: 2.625rem;
    border-radius: 0.5rem;
    border: solid 1px transparent;
    color: var(--light-gray-2);
    background-color: #000000;
    padding: 0 1rem 0 0.5rem;
}
.modal .searchbox {
    background-color: var(--bs-body-bg);
}
.searchbox:focus-within {
    border: solid 1px var(--light-gray-2);
}
.searchbox > input:not([type='submit']) {
    flex-grow: 1;
    border: none;
    background-color: transparent;
    margin: 0;
}
.searchbox > input::placeholder {
    color: var(--light-gray-2);
    font-weight: 700;
}
.searchbox > input:focus:not([type='submit']) {
    color: var(--light-gray-2);
}
.searchbox > i {
    font-size: 1.5rem;
    padding: 0.5rem 0 0 0;
    font-weight: normal;
    line-height: 1.5rem;
}

/* Loading 'spinner' */
.dot-flashing,
.dot-flashing::before,
.dot-flashing::after {
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    background-color: var(--green-2);
    animation: dot-flashing 1s infinite linear alternate;
    margin: auto;
}
.dot-flashing {
    position: relative;
    animation-delay: 0.5s;
    top: calc(50% - 0.5rem);
}
.dot-flashing::before,
.dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}
.dot-flashing::before {
    left: -1.5rem;
    animation-delay: 0s;
}
.dot-flashing::after {
    left: 1.5rem;
    animation-delay: 1s;
}
@keyframes dot-flashing {
    0% {
        background-color: var(--green-2);
    }
    50%,
    100% {
        background-color: var(--green-4);
    }
}

.full-page-back-button {
    background-color: transparent;
    color: var(--bs-body-color);
    border: none;
    font-size: 1.5rem;
    padding-right: 2rem;
}

.list-unstyled button {
    width: 100%;
    text-align: left;
}

.sourceFile-label {
    font-size: small;
    border-radius: 16px;
    border-width: 1px;
    border-style: solid;
    background-color: black;
    padding: 0.1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.btn.state-indicator {
    width: fit-content;
    display: flex;
    align-items: center;
}
.table.content-middle td {
    vertical-align: middle;
}

.app-info {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding-top: 2rem;
}
.build-info {
    td {
        padding: 0 0.25rem;

        &:first-child {
            font-weight: bold;
        }
    }
}
.releases ul {
    list-style: disc;
    padding-left: 2rem;
}

/* Flex  Layout */
.flexlayout__layout.project__layout {
    position: relative;
    height: 100%;

    pointer-events: none;

    --color-text: var(--text-high-emphasis);
    --color-background: transparent;
    --color-tabset-background: transparent;
    --color-base: var(--background);
    --color-tab-selected-background: var(--foreground);
    --color-tab-selected: var(--text-high-emphasis);
    --color-tab-unselected: var(--text-medium-emphasis);

    --tab-radius: 4px;

    .tabless {
        display: none;
    }
}

.flexlayout__splitter {
    pointer-events: auto;
    background-color: var(--background);
    &:hover {
        background-color: var(--background);
    }
}
.left-closed .flexlayout__splitter[data-layout-path='/so'],
.right-closed .flexlayout__splitter[data-layout-path='/c1/r0/s0'],
.tray-closed .flexlayout__splitter[data-layout-path='/c1/s0'] {
    display: none;
}

.flexlayout__tabset {
    position: initial;
}

:not(.tabless) + .flexlayout__tabset_content {
    background-color: var(--background);
    border: solid 2px var(--foreground);
    border-top: none;
}

.flexlayout__tabset:has(.tabless) .flexlayout__tabset_header {
    pointer-events: auto;
}

.tabless + .flexlayout__tabset_content {
    pointer-events: none;
}

.flexlayout__tabset_content:has(.tabset-placeholder) {
    display: flex;
    flex-direction: column;
}

.flexlayout__tabset:not(:has(.tabless)) {
    pointer-events: auto;
}

.flexlayout__layout .flexlayout__tabset_header {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;

    background-color: var(--foreground);
    border-bottom: none;
    background-image: none;
    box-shadow: none;
}

.flexlayout__tabset-selected {
    background-image: none;
}

.flexlayout__tabset_tabbar_inner_tab_container_top {
    border-top: none;
}

.flexlayout__tabset_tabbar_outer_top {
    border-bottom: solid 2px var(--foreground);
}

.flexlayout__tabset_tabbar_outer {
    background-color: var(--background);
}

.flexlayout__tab_button_top {
    height: 1rem;
    font-size: 12px;
    border: none;
    padding: 0 0.5rem;
}

.flexlayout__tab_button--selected {
    border: solid 1px var(--foreground);
    border-bottom: none;
    background-color: var(--foreground);
}

.flexlayout__tab_button--unselected {
    border: solid 1px var(--foreground);
    border-bottom: none;
    background-color: var(--background);
}

.flexlayout__tab_button_top {
    border-radius: var(--tab-radius) var(--tab-radius) 0 0;
}

.flexlayout__tab {
    border: solid 2px var(--foreground);
    border-top: none;
    display: flex;
    flex-direction: column;
}

.flexlayout__tab[style*='width: 0px'],
.flexlayout__tab[style*='height: 0px'] {
    display: none;
}

.flexlayout__tabset_header_content {
    max-height: 1.5rem;
}

.flexlayout__popup_menu_container {
    pointer-events: all;
}

.flexlayout__tab_toolbar {
    padding: 0;
    gap: 0.125rem;
}

.tabHeader {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    width: 100%;
    background-color: var(--foreground);
    color: var(--text-medium-emphasis);
    height: 24px;
    pointer-events: all;
    overflow: hidden;

    & > div {
        display: flex;
        gap: 0.125rem;

        &:has(span) {
            width: unset;
            overflow: hidden;

            & > span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

.tabHeaderInput {
    border: none;
    background-color: var(--background);
    height: 22px;
    min-width: 24px;
    border-radius: 4px;

    display: flex;
    flex-direction: row;

    overflow: hidden;

    & > div {
        background-color: var(--background);
        color: var(--text-medium-emphasis);
        font-size: 0.75rem;

        display: flex;
        align-items: center;

        i {
            font-size: 1rem;
            margin: 0 2px;
        }

        &:has(input:checked) {
            background-color: var(--emphasize-light);
        }
        input[type='checkbox'] {
            display: none;
        }
        &:has(button:active):not(.dropdown),
        &.dropdown > div:has(button:active),
        &:has(label:active) {
            background-color: var(--emphasize-dark);
        }

        & > .dropdown > div > button,
        & > button,
        & > label {
            min-width: 24px;
            height: 22px;
            padding: 0;

            cursor: pointer;
            background-color: transparent;
            color: var(--text-medium-emphasis);
            border: none;

            display: flex;
            justify-content: center;
            align-items: center;

            &:disabled {
                color: var(--text-disabled);
            }

            &.selected {
                background-color: var(--emphasize-light);
            }

            &.toggled:disabled {
                color: var(--text-high-emphasis);
                background-color: var(--emphasize-light);
            }
        }

        & > input[type='number'] {
            color: var(--text-medium-emphasis);
            background-color: transparent;
            width: 2rem;
            border: none;
            text-align: end;
            height: 22px;

            -moz-appearance: textfield;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        & > input[type='range'] {
            padding: 0 2px;
        }

        &:has(.dropdown) > button {
            position: absolute;
        }

        & > div.dropdown {
            display: none;
            position: absolute;
            border-radius: 4px;
            overflow: hidden;
            flex-direction: column;
            background-color: var(--background);
            top: 1.5rem;

            & > div > button {
                width: 100%;
                justify-content: flex-start;
                padding-right: 0.25rem;

                & > i {
                    width: 1.5rem;
                }
            }
        }

        &:focus-within > div.dropdown {
            display: flex;
        }
    }
}

.tabContent {
    overflow: auto;
    flex-grow: 1;
    pointer-events: initial;
    color: var(--text-medium-emphasis);

    padding: 0.25rem;
    margin: 0;
    font-size: 1rem;

    &:not(.freeScroll) {
        overflow-x: clip;
    }

    &:not(ul) {
        display: flex;
        gap: 0.25rem;
        flex-direction: column;
    }

    hr {
        margin: 0.25rem 0;
    }

    &.fixedHeight {
        height: 100%;
        overflow-y: hidden;

        .scrollable {
            overflow-y: auto;
        }
    }
}

.newTabButton {
    border: none;
    width: 16.4px;
    height: 16.4px;
    padding: 0;
    background-color: transparent;
    border-radius: 4px;
    line-height: 15px;

    &::before {
        font-family: 'Font Awesome 6 Pro';
        content: '\2b';
        font-size: smaller;
        color: var(--text-medium-emphasis);
    }

    &:hover {
        background-color: var(--foreground);
    }
}

.pinTabButton {
    border: none;
    width: 15px;
    height: 15px;
    padding: 0;
    border-radius: 4px;
    background-color: transparent;
    line-height: 13px;

    pointer-events: all;

    &::before {
        font-family: 'Font Awesome 6 Pro';
        content: '\f08d';
        font-size: smaller;
        color: var(--text-medium-emphasis);
    }

    &:hover {
        background-color: var(--foreground);
    }
}

.toolbar {
    height: 3rem;
    width: 100%;

    background-color: var(--background);
    border: 2px solid var(--foreground);
    margin-bottom: 2px;

    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;

    span {
        font-weight: bold;
    }

    hr {
        height: 2rem;
        width: 1px;

        opacity: 0.5;
        margin: 0;
        border: 0;
        border-left: solid 1px var(--text-medium-emphasis);
    }

    .button-set {
        display: flex;
    }

    .spacer {
        flex-grow: 1;
    }

    button.homeButton,
    button.settingsButton,
    .button-set > button {
        border: none;
        background-color: transparent;

        width: 2rem;
        height: 2rem;

        display: flex;

        justify-content: center;
        align-items: center;

        i {
            font-size: 1.5rem;

            &::before {
                color: var(--primary);
                opacity: 0.4;
            }

            &::after {
                color: var(--text-medium-emphasis);
                opacity: 1;
            }

            &.invert {
                &::after {
                    color: var(--primary);
                    opacity: 0.4;
                }

                &::before {
                    color: var(--text-medium-emphasis);
                    opacity: 1;
                }
            }
        }

        span {
            position: absolute;
            color: var(--emphasize-dark);
            font-weight: bold;
            font-size: 0.75rem;
        }

        &.highlight,
        &:hover:not(.homeButton) {
            background-color: var(--foreground);
        }

        &.homeButton {
            scale: 1.25;
        }
    }

    .storyController {
        display: flex;
        flex-direction: row;

        .navigation {
            color: var(--primary);
            opacity: 0.4;
        }
    }
}

.tabset-placeholder {
    flex-grow: 1;
    padding: 0.25rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .placeholder-tabset-information {
        display: flex;
        &:first-of-type {
            justify-content: end;
        }

        .pointer {
            padding-right: 2rem;
            &::before {
                font-size: 2.5rem;
            }
        }
    }
}

.admin-space,
.library-space,
.project-space {
    width: 100%;
}

.layout-space {
    background-color: var(--emphasize-dark);
    width: 100%;
    height: calc(100% - 3rem - 2px);
    overflow: visible;

    & > div {
        position: absolute;
    }
}

.dashboard {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: var(--emphasize-dark);

    .dashboard-menu {
        display: flex;
        flex-direction: column;
        background-color: var(--background);
        height: 100%;
        min-width: 15rem;
        padding-bottom: 0.5rem;
        gap: 0.25rem;
        overflow-x: hidden;

        box-shadow: var(--bs-box-shadow);

        .dashboard-logo {
            display: flex;
            gap: 0.5rem;
            align-items: flex-end;
            padding: 0.5rem 1rem;

            span {
                font-weight: 500;
                font-size: 2rem;
                color: var(--text-high-emphasis);
            }
        }

        .button-group {
            border-radius: 3px;
            box-shadow: var(--bs-box-shadow);
            overflow: hidden;
        }

        button {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            align-items: center;

            width: 100% !important;

            color: var(--text-medium-emphasis);
            background-color: var(--foreground);
            border: none;

            font-size: 1rem;
            font-weight: bold;

            padding: 0.5rem 1rem;
            text-align: left;

            &.highlight,
            &:hover {
                color: var(--text-high-emphasis);
                background-color: var(--emphasize-light);
            }

            div {
                border-radius: 50%;
                background-color: var(--X);
                height: 1rem;
                width: 1rem;
                text-align: center;
                vertical-align: middle;
                font-size: 0.75rem;
                line-height: 1rem;
                color: var(--text-high-emphasis);
            }
        }

        .spacer {
            flex-grow: 1;
        }
    }

    .dashboard-area {
        flex-grow: 1;
        padding: 0.5rem;
        gap: 0.5rem;

        display: flex;
        flex-direction: row;

        .dashboard-column {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            flex: 1 1 25rem;

            .dashboard-pane {
                background-color: var(--background);
                flex: 1 1 20rem;

                display: flex;
                flex-direction: column;

                overflow: hidden;
                padding: 0.25rem;

                border-radius: 3px;
                box-shadow: var(--bs-box-shadow);

                &.viewport {
                    padding: 0;
                    & > div {
                        position: relative;
                        overflow: unset;
                        width: 100%;
                        height: 100%;
                    }
                }

                &.collapsing {
                    height: fit-content;
                    flex: unset;

                    & > div {
                        flex-grow: unset;
                        height: fit-content;
                        overflow: unset;
                    }
                }

                & > span {
                    font-size: 1.5rem;
                    font-weight: 500;
                    padding-inline-start: 0.25rem;
                    line-height: 1.75rem;
                }

                & > div {
                    background-color: var(--background);
                    flex-grow: 1;
                    overflow-y: auto;

                    &.fixed {
                        overflow-y: hidden;

                        .list {
                            overflow-y: auto;
                        }
                    }
                }

                & > div.chin {
                    background-color: var(--background);
                    flex-grow: 0;
                    flex-shrink: 1;

                    overflow: hidden;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-evenly;
                    gap: 0.5rem;
                    padding: 0.25rem;
                }
            }
        }
    }

    .pane-button {
        box-shadow: var(--bs-box-shadow);
        flex-grow: 1;
        &.large {
            border: none;
        }
    }
}

/* Inputs */
.pane-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border: none;
    border-radius: 5px;
    background-color: var(--foreground);
    color: var(--text-high-emphasis);
    min-height: 24px;
    min-width: 24px;
    width: fit-content;
    padding-inline: 5px;
    font-size: 0.9rem;

    &:hover:not(:disabled) {
        outline: solid 1px var(--emphasize-light);
    }

    &:active {
        background-color: var(--emphasize-light);
    }

    &:disabled {
        color: var(--text-disabled);
    }

    &.highlight {
        background-color: var(--primary-split);
        color: black;

        &:active {
            background-color: var(--primary-split-desaturated);
        }

        &:disabled {
            color: var(--text-disabled);
            background-color: var(--foreground);
        }
    }

    &.alternate-highlight {
        background-color: var(--primary);
        color: black;

        &:active {
            background-color: var(--primary-light);
        }
    }

    &.danger {
        background-color: var(--red-2);
        color: black;

        &:active {
            background-color: var(--red-3);
        }
    }

    &.selected {
        text-decoration: underline;
    }

    & > i {
        padding: 0;
        font-size: 1rem;
    }

    &.large {
        min-height: 32px;
        padding-inline: 8px;
        padding: 0 8px;
        font-size: 1rem;

        border: solid 2px var(--emphasize-light);
        &:hover {
            outline: solid 1px var(--emphasize-light);
        }

        &.icon {
            padding-inline: 0;
            min-width: 32px;
        }
    }
}

/* label:has(button:not(:disabled)),
label:has(input[type="checkbox"]:not(:disabled)) {
    cursor: pointer;
} */

.input-row {
    display: flex;
    gap: 0.25rem;
    align-items: center;
}

.radio,
.checkbox {
    display: flex;
    gap: 0.25rem;
    flex-grow: 1;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    span {
        flex-grow: 1;
    }

    &::before {
        font-family: 'Font Awesome 6 Pro';
        color: var(--text-medium-emphasis);
    }

    &.checkbox {
        &::before {
            content: '\f0c8';
        }

        &:has(input:checked)::before {
            content: '\f14a';
        }
    }

    &.radio {
        &::before {
            content: '\f111';
        }

        &:has(input:checked)::before {
            content: '\f192';
        }
    }

    &:has(input:disabled)::before {
        color: var(--text-disabled);
    }

    &:not(:has(input:disabled)):hover::before {
        color: var(--primary-split);
    }

    &:has(input:focus-visible) {
        outline: solid 1px var(--text-high-emphasis);
    }
}

.toggle-switch {
    display: flex;

    height: 1rem;
    min-width: 2rem;
    max-width: 2rem;
    background-color: var(--primary-split-desaturated);
    border-radius: 0.5rem;
    transition: 0.2s;
    outline: solid 1px var(--text-medium-emphasis);

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    & > span {
        position: relative;
        top: 0;
        left: 0;

        display: block;

        &::before {
            position: absolute;
            top: 0.125rem;
            left: 0.125rem;
            content: '';
            width: 0.75rem;
            height: 0.75rem;
            background-color: var(--text-high-emphasis);
            border-radius: 50%;
            transition: 0.2s;
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -ms-transform: translate(0);
        }
    }

    &:has(input:checked) {
        background-color: var(--primary-split);

        span::before {
            transform: translateX(1rem);
            -webkit-transform: translateX(1rem);
            -ms-transform: translate(1rem);
        }
    }

    &:has(input:disabled) {
        opacity: 40%;
        span {
            cursor: auto;
        }
    }

    &:has(input:focus-visible) {
        outline: solid 1px var(--text-high-emphasis);
    }
}

.tri-state {
    display: block;
    background-color: var(--primary-split-desaturated);
    outline: solid 1px var(--text-medium-emphasis);
    border-radius: 0.5rem;
    height: 1rem;
    margin: 0.125rem;

    transition: 0.2s;

    &.left,
    &.right {
        background-color: var(--primary-split);
    }

    &.disabled {
        opacity: 0.5;
    }

    button {
        height: 100%;
        border: none;
        background-color: transparent;
    }

    & > span {
        position: relative;
        top: 0;
        left: 0;
        width: 0 !important;
        margin: 0 !important;

        display: block;
        pointer-events: none;

        &::before {
            position: absolute;
            top: -0.375rem;
            left: 0.75rem;
            content: '';
            width: 0.75rem;
            height: 0.75rem;
            background-color: var(--text-high-emphasis);
            border-radius: 50%;
            transition: 0.2s;
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -ms-transform: translate(0);

            text-align: center;

            font-family: 'Font Awesome 6 Pro';
            color: var(--emphasize-dark);
            font-size: 0.5rem;
            content: '\f111';
        }

        &.left::before {
            left: 0.125rem;
            content: '\f053';
        }
        &.right::before {
            left: 1.375rem;
            content: '\f054';
        }
    }
}

.info {
    color: var(--primary-split);
}

.warning {
    color: var(--quality-0);
}

input.settings-slider {
    height: 16px;
    -webkit-appearance: none;
    width: 5rem;
    flex-shrink: 1;
    background-color: transparent;

    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        background: var(--text-disabled);
        border-radius: 5px;
    }
    &::-webkit-slider-thumb {
        height: 14px;
        width: 4px;
        border-radius: 2px;
        background: var(--text-medium-emphasis);
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -5px;
    }
    &:focus::-webkit-slider-runnable-track {
        background: var(--text-disabled);
    }
    &::-moz-range-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        background: var(--text-disabled);
        border-radius: 2px;
    }
    &::-moz-range-thumb {
        height: 14px;
        width: 4px;
        border-radius: 2px;
        background: var(--text-medium-emphasis);
        cursor: pointer;
        border: none;
    }
    &::-ms-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }
    &::-ms-fill-lower,
    &::-ms-fill-upper {
        background: var(--text-disabled);
        border-radius: 2px;
    }
    &::-ms-thumb {
        margin-top: 2px;
        height: 14px;
        width: 4px;
        border-radius: 2px;
        background: var(--text-medium-emphasis);
        cursor: pointer;
    }
    &:focus::-ms-fill-lower,
    &:focus::-ms-fill-upper {
        background: var(--text-disabled);
    }
}

.settings-slider-text {
    min-width: 2.75rem;

    input {
        width: 2.5rem;
        text-align: right;
        border: none;
        color: var(--text-medium-emphasis);

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &[type='number'] {
            -moz-appearance: textfield;
        }
    }

    span {
        min-width: 0.75rem;
    }
}
span.settings-slider-text {
    text-align: right;
}

.settings-input {
    background-color: var(--emphasize-dark);

    input {
        color: var(--text-medium-emphasis);
        background: none;
        border: none;
        text-align: right;
        width: 5rem;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &[type='number'] {
            -moz-appearance: textfield;
        }
    }
}

.settings-select {
    background-color: var(--emphasize-dark);
    width: 5.75rem;
    height: 22px;
    align-content: center;

    .settings-select-control {
        min-height: 16px;
        max-height: 16px;
    }

    .settings-select-value-container {
        justify-content: end;
    }

    .settings-select-menu {
        background-color: var(--emphasize-dark);
        text-align: right;
    }

    .settings-select-dropdown-indicator {
        padding: 0 0.125rem;
    }
}

.list-search {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 2px 4px;

    background-color: var(--emphasize-dark);
    color: var(--text-medium-emphasis);

    input {
        background-color: transparent;
        border: none;
        color: var(--text-medium-emphasis);
        flex-grow: 1;

        &:focus-visible {
            outline: none;
        }
    }
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}
::-webkit-scrollbar-track {
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: var(--emphasize-light);
    border-radius: 8px;
    border: 6px solid transparent;
    background-clip: content-box;
}
::-webkit-scrollbar-corner {
    background-color: transparent;
}

/* Lists */
ul {
    padding-left: 0;
}

ul.list {
    list-style-type: none;
    margin: 0;

    ul {
        list-style-type: none;
        padding-left: 0.25rem;
    }

    label:has(button:not(:disabled)) {
        cursor: pointer;
    }
}

ul.list,
.list ul {
    li {
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:nth-child(2n)&:not(:has(ul)) {
            background-color: var(--deep-background);

            &.highlight {
                background-color: var(--primary-split-desaturated);
            }
        }

        & > button {
            margin: 0.25rem;
        }

        & > div:first-child {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            gap: 0.25rem;
            align-items: center;
            padding: 0 0.25rem;
            min-height: 1.5rem;

            span:first-child {
                display: flex;
                gap: 0.25rem;
                align-items: center;
                flex-grow: 1;
                flex-shrink: 1;

                text-wrap: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            label button {
                color: var(--text-high-emphasis);

                & > span {
                    color: var(--text-medium-emphasis);
                }
            }

            label > button {
                padding: 0;
                border: none;
                background-color: transparent;

                & > i {
                    padding-right: 0.25rem;
                }
            }

            label:first-of-type:not(:has(input[type='checkbox'])) {
                flex-grow: 1;
                flex-shrink: 1;

                text-wrap: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            label.custom {
                color: var(--primary-light);
            }

            &.dropdown label:first-child {
                display: flex;
                gap: 0.25rem;
                align-items: center;
                flex-grow: 1;
                flex-shrink: 1;
                cursor: pointer;

                text-wrap: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }

                &::before {
                    font-family: 'Font Awesome 6 Pro';
                    font-weight: 900;
                    content: '\f0d8';
                }

                &:has(input:checked)::before {
                    content: '\f0d7';
                }
            }
        }

        &.hovered {
            background-color: var(--emphasize-dark);
        }

        &.highlight {
            background-color: var(--primary-split-desaturated);
        }

        & > ul {
            margin-left: 7px;
            border-left: solid 2px var(--foreground);

            & > li:nth-child(2n) {
                background-color: var(--emphasize-dark);
                &.highlight {
                    background-color: var(--primary-split-desaturated);
                }
            }
        }

        .btn.indicator {
            padding: 0;
            --bs-btn-active-color: var(--text-disabled);
            --bs-btn-hover-color: var(--text-disabled);
            --bs-btn-color: var(--text-disabled);
            cursor: default;
        }
    }
}

.popover ul,
.popover-style ul {
    margin: 0;
    color: var(--text-medium-emphasis);
    font-size: 0.875rem;

    hr {
        margin: 0;
        border-color: var(--foreground);
        opacity: 1;

        &:last-child:not(.force),
        &:not(.force) + hr {
            display: none;
        }
    }

    & > li.experimental > label {
        & > div:nth-child(1) {
            border-right: solid 2px var(--primary);
        }
        & > div:nth-child(2).title {
            padding: 0 4px;
            color: var(--primary);
        }
    }

    & > li {
        & > label {
            display: flex;
            min-height: 20px;

            &:hover {
                background-color: var(--emphasize-light);

                > div:nth-child(1) {
                    background-color: var(--background);
                }
            }

            > div {
                display: flex;
                align-items: center;
            }

            > div:nth-child(1) {
                display: flex;
                justify-content: center;

                background-color: var(--emphasize-dark);
                width: 1.5rem;

                label {
                    line-height: 0;
                    transform: translateX(6px);
                }
            }

            > div:nth-child(2) {
                flex-grow: 1;

                label {
                    margin: 0 4px;
                }

                button {
                    border: none;
                    background-color: transparent;
                    color: var(--text-medium-emphasis);
                    padding: 0;
                    margin: 0 4px;
                }

                &.title {
                    padding: 0 4px;
                    background-color: var(--emphasize-dark);
                }
            }

            > div:nth-child(3) {
                > span {
                    text-align: right;
                    width: 2.5rem;
                    margin: 0 4px;
                }

                &:not(:has(input[type]), .tri-state),
                &:has(input[type='number']) {
                    background-color: var(--emphasize-dark);
                    margin: 1px;
                    display: flex;

                    input {
                        /* flex-grow: 1; */
                        flex-shrink: 1;
                        flex-basis: 5rem;
                        background-color: transparent;
                        border: none;
                        color: var(--text-medium-emphasis);
                        text-align: right;
                        line-height: 19px;

                        -moz-appearance: textfield;

                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }
                    span {
                        flex-shrink: 1;
                        width: unset;
                        margin: 0;
                        margin-right: 2px;
                        &:empty {
                            margin-right: 0;
                        }
                    }

                    .incrementers {
                        display: flex;
                        flex-direction: column;
                        padding-right: 0.125rem;

                        button {
                            background-color: transparent;
                            border: none;
                            color: var(--text-medium-emphasis);
                            padding: 0;
                            font-size: 0.5rem;
                            line-height: 0.25rem;
                            height: 0.625rem;
                            width: 0.5rem;

                            &:hover {
                                background-color: var(--background);
                            }
                        }
                    }
                }

                &.select {
                    min-width: 10rem;

                    &.narrow {
                        min-width: 5rem;
                    }
                }

                select {
                    background-color: var(--emphasize-dark);
                    color: var(--text-medium-emphasis);
                    border: none;
                    width: 5rem;
                    text-align: right;
                    margin: 1px;
                }
            }

            .color-pickers {
                display: flex;
                gap: 2px;
                justify-content: space-around;
                background-color: var(--emphasize-light);
                margin: 1px;
                padding: 2px;

                .color-picker {
                    width: 15px;
                    height: 15px;
                    overflow: hidden;
                    border: none;

                    &.active {
                        outline: solid 2px #54b058 !important;
                    }
                    &.rainbow {
                        background: conic-gradient(red, orange, yellow, green, blue, red);
                    }
                    &.white {
                        background-color: white;
                    }
                    &.black {
                        background-color: black;
                    }

                    input[type='color'] {
                        cursor: pointer;
                        height: 1.5rem;
                        width: 1.5rem;
                        background: attr(bg-color) 0% 0% no-repeat padding-box;
                        opacity: 0;
                        transform: translateX(-2px) translateY(-2px);
                    }
                }
            }

            .legend-limits {
                display: flex;
                justify-content: space-between;
            }

            .horizontal-slider {
                height: 2rem;
                margin: 1px;
                flex-grow: 1;
                display: flex;
                flex-direction: row;

                .slider-thumb {
                    width: 5px;
                    border: solid var(--text-medium-emphasis) 1px;
                    height: 100%;
                    background-color: var(--emphasize-dark);
                    cursor: pointer;
                }

                .slider-track {
                    height: 100%;
                    width: unset;
                }

                .scale-bar {
                    height: 100%;
                    width: 100%;
                    display: flex;
                }
                .scale-bar-span {
                    flex-grow: 1;
                    height: 100%;
                }

                .elevation-slider-inactive {
                    height: 100%;
                    width: unset;
                    opacity: 50%;
                    background-color: var(--primary-split);
                }
                .elevation-slider-active {
                    height: 100%;
                    width: unset;
                    background-color: var(--primary-split);
                }
            }

            canvas {
                width: 10rem;
                padding: 1px;
            }
        }
    }
}

li.listed-scale {
    padding-bottom: 0.5rem;
    border-bottom: solid 2px var(--foreground);

    & > div:first-child {
        color: var(--text-high-emphasis);
        font-size: 1rem;
    }

    & > button {
        border: none;
        padding: 0;
    }

    .legend-limits {
        display: flex;
        justify-content: space-between;
        color: var(--text-high-emphasis);
    }

    .horizontal-slider {
        height: 2rem;
        width: 100%;

        .scale-bar {
            height: 100%;
            width: 100%;
            display: flex;
        }
        .scale-bar-span {
            flex-grow: 1;
            height: 100%;
        }
    }
}

.handle {
    padding: 0;

    i {
        padding: 0;
    }
}

.inspector-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 0;

    & > span:first-of-type {
        color: var(--text-high-emphasis);
        font-size: larger;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.annotation-title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;
}

.annotation-info {
    color: var(--blue-3);
}

.coordinates-input {
    input {
        width: 8rem;
    }

    li:nth-child(1) span {
        color: var(--X-desaturated);
    }

    li:nth-child(2) span {
        color: var(--Y-desaturated);
    }

    li:nth-child(3) span {
        color: var(--Z-desaturated);
    }
}

.cursor label,
label.cursor {
    cursor: pointer;
}

.buttonRow {
    display: flex;
}

.orderingOptions {
    width: 8rem;
}

/* Container for the entire release section */
.release-container {
    padding: 20px;
    background-color: var(--background);
    border-radius: 6px;
    margin-bottom: 20px;
}

/* Styling for the release header */
.release-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 2px solid #ffffff;
    padding-bottom: 10px;
}

/* Version and date styling */
.release-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #ffffff;
}

.release-date {
    font-size: 0.9rem;
    font-weight: 400;
    color: #ffffff;
}

.release-note-subtitle {
    font-size: 1.1rem;
    font-weight: 600;
    color: #ffffff;
}

/* New features section */
.features-section h5,
.improvements-section h5 {
    font-size: 1.1rem;
    font-weight: 500;
}

/* Improvements list */
.improvements-list {
    padding-left: 20px;
}

.improvements-list li {
    margin-bottom: 5px;
}

/* Fix modal size and center it */
.fixed-modal .modal-dialog {
    width: 800px; /* Fixed width */
    height: 600px; /* Fixed height */
    max-width: 100%; /* Ensure responsiveness on smaller screens */
    max-height: 100%; /* Ensure responsiveness on smaller screens */
    margin: auto; /* Center it vertically and horizontally */
}

/* Ensure the modal body is scrollable if content overflows */
.modal-body-scrollable {
    height: 600px; /* Adjust this height according to the header and footer */
    overflow-y: auto; /* Add vertical scrollbar when content exceeds available height */
    padding: 15px; /* Padding for content readability */
}

/* Make sure the modal is always centered */
.fixed-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fixed-modal .modal-content {
    height: 100%;
}

.chartContainer {
    display: flex;
    flex-direction: row;

    height: calc(100% - 1.5rem);
    width: 100%;

    ul.legends {
        overflow-y: auto;
        overflow-x: clip;
        padding: 0.25rem;
        padding-right: 0;

        li {
            white-space: nowrap;

            div {
                display: flex;
                align-items: center;
                gap: 0.25rem;

                &.right {
                    justify-content: end;
                }
            }

            i {
                size: 0.75rem;
            }
        }
    }

    & > div {
        display: flex;
        flex-direction: column;

        flex-grow: 1;
        flex-shrink: 1;

        span {
            text-align: center;
        }

        & > span {
            color: var(--text-medium-emphasis);
            width: 100%;
        }

        & > div {
            display: flex;
            flex-direction: row;

            flex-grow: 1;

            & > span {
                color: #777;
                font-size: 0.9rem;
                writing-mode: vertical-lr;
                rotate: 180deg;
            }
        }
    }

    .echarts-for-react {
        pointer-events: all;
    }

    &.light {
        background-color: var(--light-gray-3);
        & > div > span,
        ul.legends {
            color: var(--background);
        }
    }
}

.attributes-dropdown {
    background-color: transparent;
    color: var(--text-medium-emphasis);
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
